
@media (max-width: 767px) {
    .modal-dialog.custom-modal {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: calc(100vh - 2rem);
      margin: 1rem auto;
      width: 70%;
      max-width: none;
    }
  }
  