.emsnav {
    padding-left: 1rem;
    padding-right: 1rem;
    border-bottom: 1px solid #dadce0;
  }
  
  .navlogo {
    max-height: 3rem;
  }
  
  .nav-center {
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-bottom: 1px solid #dadce0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200%;
  }
  .form-center {
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .d-flex {
    flex-grow: 1;
    max-width: 400px;
  }
  
  .d-flex .form-control {
    flex-grow: 1;
  }
  
  
  .emsnavlinks {
    padding-left: 1rem;
  }
  
  .emsnavlinks a {
    margin-right: 1.5rem;
  }
  
  .nav-link {
    --bs-nav-link-color: black;
    --bs-nav-link-hover-color: #4b6f6f;
  }
  
  .create-dropdown .dropdown-toggle {
    color: black;
    border: none;
    background-color: #ffffff;
  }
  
  .create-dropdown .dropdown-toggle:hover {
    color: #4b6f6f;
    border: none;
    background-color: #ffffff;
  }
  
  .create-dropdown .dropdown-menu {
    background-color: #ffffff;
  }
  
  .create-dropdown .dropdown-item {
    color: black;
  }

  .notification-dropdown .dropdown-toggle {
    color: black;
  }

  .notification-dropdown .dropdown-toggle:hover {
    color: #4b6f6f;
  }
  .notification-dropdown .dropdown-menu {
    color: black;
  }
  
  .nav-link.active {
    color: red;
  }
  
  .product-title {
    font-weight: bold;
    font-size: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  
  /* Burger menu styles */
  .bm-burger-button {
    position: fixed;
    width: 15px;
    height: 15px;
    left: 36px;
    top: 36px;
  }
  
  .bm-burger-bars {
    background: #373a47;
  }
  
  .bm-burger-bars-hover {
    background: #4b6f6f;
  }
  
  .bm-cross-button {
    left: 140px;
    top: 36px;
    height: 24px;
    width: 24px;
  }
  
  .bm-cross {
    background: #bdc3c7;
  }
  
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
    width: 175px; 
  }
  
  .bm-menu {
    background: #ffffff;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    width: 175px; 
  }
  
  .bm-morph-shape {
    fill: #ffffff;
  }
  
  .bm-item-list {
    color: black;
    padding: 0.8em;
  }
  
  .bm-item {
    display: inline-block;
  }
  
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
  
  /* Override styles for React Bootstrap components within the burger menu */
  .bm-menu .nav-link {
    --bs-nav-link-color: black;
    --bs-nav-link-hover-color: #4b6f6f;
  }
  
  .bm-menu .notification-dropdown  .dropdown-toggle {
    color: black;
  }
  
  .bm-menu .notification-dropdown  .dropdown-menu {
    color: black;
  }
  
  .bm-menu .notification-dropdown .dropdown-toggle:hover {
    color: #4b6f6f;
  }
